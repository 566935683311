import React from 'react'
import { Container, Grid, Link, Typography } from '@material-ui/core'

function App() {
  return (
    <Container>
      <Grid container alignItems='center' direction='column'>
        {[<Typography variant='h2' gutterBottom>EasyTournament.io</Typography>,
        <Typography variant='h4' gutterBottom>
          The world is moving past spreadsheets.
        </Typography>,
        <Typography variant='h3' gutterBottom>
          Coming July 2020!
        </Typography>,
        <Typography variant='body1' paragraph align="center">
        Hi, I'm John. I like Chess. The first chess tournament I entered was a digital security nightmare.
        A public google docs spreadsheet, phone numbers, names, emails, it was a hacker's dream.
        I started hunting for better tools to run tournaments. The leading platforms are either built
        way too specific for other sports, or way too expensive.
      </Typography>,
      <Typography variant='body1' paragraph align="center">
        Coming later this summer, some friends and I will be releasing a tournament app for the modern day.
        <br/>
        Secure, fast, and easier to use than spreadsheets, we're making tournaments easy enough for anyone to run.
      </Typography>,
      <br/>,
      <Link href="http://eepurl.com/hBWZSj" variant="h5">Sign up here for an email as we get closer to launch.</Link>,
      <br/>,
      <Typography variant="h5" paragraph>Want alpha access? Have a feature request?
            <Link href="mailto:support@easytournament.io"> Email Support@EasyTournament.io</Link>
      </Typography>,
      <br/>,
      <Typography variant="subtitle2">Android and iOS Eventually... Anybody know Ionic?</Typography>
        ].map((x) =>
          <Grid item>
            {x}
          </Grid>)

        }
      </Grid>
      <Typography variant="body2">Icon by Ben Davis</Typography>
    </Container>
  );
}

export default App;
